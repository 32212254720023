import React from "react";
import Navbar from "../components/Navbar";

import img from "../assets/Contact Us.webp";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import Findagent from "../components/Findagent";

export default function Contact() {
  return (
    <>
      <Navbar />
      <main>
        <Hero title="Contact Us" img={img} />
        <section className="h-[340px] px-7 md:px-40 flex flex-col justify-center gap-6">
          <h3 className=" text-center text-gray-700 ">
            If you have any feedback in regards to LJ Hooker please fill out the
            feedback form and a member from our Customer Care team will be in
            contact.
          </h3>
          <div className="flex  justify-center gap-10">
            <div className="h-[150px] w-[150px] border-[4px] rounded-full flex justify-center items-center cursor-pointer">
              <div className="gradient-border flex justify-center items-center">
                <h1 className="w-2/3 text-center text-xl">General Enquiry</h1>
              </div>
            </div>
            <div className="h-[150px] w-[150px] border-[4px] rounded-full flex justify-center items-center cursor-pointer">
              <div className="gradient-border flex justify-center items-center">
                <h1 className="w-2/3 text-center text-xl">Feedback</h1>
              </div>
            </div>
          </div>
        </section>
        <Findagent />
        <section className="h-[450px] flex justify-center items-center px-7 md:px-40 py-20 bg-[url('https://www.ljhooker.com.au/hubfs/shutterstock_20545118.jpg')] bg-fixed">
          <div className="h-full w-full bg-[#ffffffb0] flex flex-col justify-center gap-14 items-center">
            <h1 className="text-center text-2xl">Looking to join our team?</h1>
            <button className="bg-black text-white px-4 py-2 md:w-1/4 rounded-sm hover:bg-[#ffffffb0] hover:text-black border-[1px] border-black ease-in duration-200">
              Visit our careers website
            </button>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
