import React from "react";
import Navbar from "../components/Navbar";
import img from "../assets/SellHero.webp";
import img1sell from "../assets/1Sell.webp";
import img2sell from "../assets/2Sell.webp";
import Hero from "../components/Hero";
import Findagent from "../components/Findagent";
import Footer from "../components/Footer";

export default function Sell() {
  return (
    <>
      <Navbar />
      <main>
        <Hero
          title="Selling Real Estate"
          content="Whether you are ready to sell your property or just starting the journey, we can help you with insights, data and local expertise."
          img={img}
          text="text-white"
        />
        <Findagent />
        <section>
          <div className="px-7 md:px-40">
            <div className="md:flex items-center my-10">
              <div className="w-full md:w-1/2 flex flex-col gap-5 bg-[#F3F3F3] p-7 md:p-20">
                <h1 className="text-2xl">Why sell with LJ Hooker</h1>
                <p className="text-[14px] text-gray-700">
                  Established in 1928, LJ Hooker has grown to become Australia's
                  best-known and most trusted real estate brand consistently
                  ranked #1 by Newspoll, Galaxy and Qualtrics over the past
                  decade.
                </p>
                <p className="text-[14px] text-gray-700">
                  LJ Hooker has repeatedly won Real Estate Business Network of
                  the Year, Digital Presence of the Year, and Training and
                  Education Program of the Year, amongst others.
                </p>
                <button className="bg-black text-white px-2 py-1 rounded-sm border-[2px] border-black hover:bg-white hover:text-black duration-200 ease-in">
                  Read More
                </button>
              </div>
              <div className="w-full md:w-1/2 h-[300px] md:h-[700px]">
                <img
                  src={img1sell}
                  alt="Homepage"
                  className="h-full w-full object-cover"
                />
              </div>
            </div>
            <div className="md:flex items-center my-10">
              <div className="w-full md:w-1/2 h-[300px] md:h-[700px]">
                <img
                  src={img2sell}
                  alt="Homepage"
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="w-full md:w-1/2 flex flex-col gap-5 bg-[#F3F3F3] p-7 md:p-20">
                <h1 className="text-2xl">
                  Get a free appraisal to understand your property's worth
                </h1>
                <p className="text-[14px] text-gray-700">
                  A property appraisal is a great way to get insights and better
                  understand how much your home or investment is worth in the
                  current market.
                </p>
                <p className="text-[14px] text-gray-700">
                  You will find out what the market is doing, how your property
                  compares to similar properties in your area, recent local
                  sales, potential areas of improvement to maximise your selling
                  price, and much more.
                </p>

                <button className="bg-black text-white px-2 py-1 rounded-sm border-[2px] border-black hover:bg-white hover:text-black duration-200 ease-in">
                  Read more
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
