import React, { useState } from "react";
import img from "../assets/ljh-logo-black_152x30.svg";
import { LuSearch } from "react-icons/lu";
import { FaBars } from "react-icons/fa6";
import { RxCross1 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

export default function Navbar() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <header className="">
        <nav className="flex w-full px-5 md:px-20 py-6 justify-between">
          <div className=" cursor-pointer">
            <img src={img} alt="logo" onClick={() => navigate("/")} />
          </div>
          <ul className="hidden md:flex gap-8">
            <li
              className="hover:text-gray-600 duration-200 ease-in cursor-pointer"
              onClick={() => navigate("/buy")}
            >
              Buy
            </li>
            <li
              className="hover:text-gray-600 duration-200 ease-in cursor-pointer"
              onClick={() => navigate("/sell")}
            >
              Sell
            </li>
            <li
              className="hover:text-gray-600 duration-200 ease-in cursor-pointer"
              onClick={() => navigate("/contact")}
            >
              Contact
            </li>
            <li className=" text-2xl hover:text-gray-600 duration-200 ease-in cursor-pointer">
              <LuSearch />
            </li>
          </ul>
          <ul className="md:hidden">
            <li
              className="z-30 text-2xl hover:text-gray-600 duration-200 ease-in cursor-pointer"
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? <RxCross1 /> : <FaBars />}
            </li>
          </ul>
        </nav>
      </header>
      {isOpen && (
        <div className="z-10  top-0 left-0 w-full bg-white lg:hidden">
          <ul className="text-center py-6 flex flex-col gap-5">
            <li
              className="cursor-pointer hover:text-gray-600 ease-in duration-100"
              onClick={() => navigate("/buy")}
            >
              Buy
            </li>
            <li
              className="cursor-pointer hover:text-gray-600 ease-in duration-100"
              onClick={() => navigate("/sell")}
            >
              Sell
            </li>
            <li
              className="cursor-pointer hover:text-gray-600 ease-in duration-100"
              onClick={() => navigate("/contact")}
            >
              Contact
            </li>
          </ul>
        </div>
      )}
    </>
  );
}
