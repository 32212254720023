import React from "react";
import { IoMdSearch } from "react-icons/io";

export default function Findagent() {
  return (
    <section>
      <div className="h-[250px] px-7 flex flex-col bg-[#F3F3F3] justify-center md:items-center gap-7">
        <h1 className="text-2xl md:text-3xl ">
          Find an agent or your local LJ Hooker office
        </h1>
        <p className="text-[14px] text-gray-600">
          LJ Hooker has offices nationwide and our agents are local experts. Let
          us help you in your real estate journey.
        </p>
        <div className="md:w-1/2 flex">
          <input
            type="text"
            className="w-full border-[1px] rounded-sm px-2 py-[2px] text-[14px]"
            placeholder="Enter agent name, office or suburb"
          />
          <button className="bg-black px-3 rounded-sm text-white text-[22px] hover:bg-white hover:text-black duration-200 ease-in border-[1px]">
            <IoMdSearch />
          </button>
        </div>
      </div>
    </section>
  );
}
