import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import img from "../assets/shutterstock_1887892210_header.webp";
import imgbuy1 from "../assets/1Buy.webp";
import imgbuy2 from "../assets/2Buy.jpg";
import Findagent from "../components/Findagent";
import Footer from "../components/Footer";

export default function Buy() {
  return (
    <>
      <Navbar />
      <main>
        <Hero
          title="Buying Real Estate"
          content="Buying a new property is an exciting time, but it can also be overwhelming. We are here to guide and support you every step of the way."
          img={img}
          search="true"
        />
        <Findagent />
        <section>
          <div className="px-7 md:px-40">
            <div className="md:flex items-center my-10">
              <div className="w-full md:w-1/2 flex flex-col gap-5 bg-[#F3F3F3] p-7 md:p-20">
                <h1 className="text-2xl">Why buy with LJ Hooker</h1>
                <p className="text-[14px] text-gray-700">
                  There's nothing more exciting than buying a property. Still,
                  it can quickly become overwhelming, especially if this is your
                  first purchase. Between scouring online listings to find your
                  dream home, attending numerous open house inspections,
                  organising finances and negotiating, there is a lot to know
                  and think about.
                </p>
                <p className="text-[14px] text-gray-700">
                  Enlisting the help of a trusted real estate agent can help you
                  navigate the often tricky world of real estate. At LJ Hooker,
                  we can help and provide you with expert advice and assistance
                  relevant to your local property market.
                </p>
                <button className="bg-black text-white px-2 py-1 rounded-sm border-[2px] border-black hover:bg-white hover:text-black duration-200 ease-in">
                  Read More
                </button>
              </div>
              <div className="w-full md:w-1/2 h-[300px] md:h-[700px]">
                <img
                  src={imgbuy1}
                  alt="Homepage"
                  className="h-full w-full object-cover"
                />
              </div>
            </div>
            <div className="md:flex items-center my-10">
              <div className="w-full md:w-1/2 h-[300px] md:h-[700px]">
                <img
                  src={imgbuy2}
                  alt="Homepage"
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="w-full md:w-1/2 flex flex-col gap-5 bg-[#F3F3F3] p-7 md:p-20">
                <h1 className="text-2xl">Different ways to buy real estate</h1>
                <p className="text-[14px] text-gray-700">
                  There are different ways you can use to purchase a property,
                  and understanding them all with their pros and cons is an
                  essential step of the buying journey. It may seem a little
                  daunting, but with the help of a real estate agent to guide
                  you, you will be just fine.
                </p>
                <p className="text-[14px] text-gray-700">
                  In Australia, there are four main selling methods: auction,
                  private treaty, tender and expressions of interest.
                </p>

                <button className="bg-black text-white px-2 py-1 rounded-sm border-[2px] border-black hover:bg-white hover:text-black duration-200 ease-in">
                  Read more
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
