import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import img from "../assets/Homepage.webp";
import imghome1 from "../assets/1Homepage.webp";
import imghome2 from "../assets/2HomepageInvestment.webp";
import Findagent from "../components/Findagent";
import Footer from "../components/Footer";

export default function Home() {
  return (
    <>
      <Navbar />
      <main>
        <Hero
          title="When you know, you know."
          content="Real estate in Australia"
          img={img}
          search="true"
          text="text-white"
        />
        <section>
          <div className="h-[330px] flex justify-center items-center">
            <div className="px-7 md:px-40">
              <h1 className="text-2xl md:text-3xl md:text-center mb-5 md:mb-10">
                They say trust is everything, and for over 90 years you’ve
                trusted us to look after your real estate needs
              </h1>
              <p className="text-[14px] text-gray-700 md:text-center">
                LJ Hooker is Australia's most iconic real estate network. We’re
                committed to guiding you through the journey and to help you
                make the right choice for you. So, when you know you’re ready,
                you know we’re here for you.
              </p>
            </div>
          </div>
        </section>
        <Findagent />
        <section>
          <div className="px-7 md:px-40">
            <div className="md:flex items-center my-10">
              <div className="w-full md:w-1/2 h-[300px] md:h-[700px]">
                <img
                  src={imghome1}
                  alt="Homepage"
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="w-full md:w-1/2 flex flex-col gap-5 bg-[#F3F3F3] p-7 md:p-20">
                <h1 className="text-2xl">
                  Sell your property with Australia’s iconic real estate brand
                </h1>
                <p className="text-[14px] text-gray-700">
                  Are you looking to sell? Get a free property appraisal with
                  your local LJ Hooker agent, either in-person or virtual, to
                  find out how much your property is worth.
                </p>
                <p className="text-[14px] text-gray-700">
                  Your free appraisal will contain a detailed breakdown with an
                  update on market conditions, how your property compares to
                  similar properties based on local sales and listings, and much
                  more.
                </p>
                <button className="bg-black text-white px-2 py-1 rounded-sm border-[2px] border-black hover:bg-white hover:text-black duration-200 ease-in">
                  Get my free property appraisal
                </button>
              </div>
            </div>
            <div className="md:flex items-center my-10">
              <div className="w-full md:w-1/2 flex flex-col gap-5 bg-[#F3F3F3] p-7 md:p-20">
                <h1 className="text-2xl">
                  Maximise the potential of your property investment with
                  Australia's #1 real estate brand
                </h1>
                <p className="text-[14px] text-gray-700">
                  Are you making the most out of your investment property? Or
                  are you looking for a property to invest in?
                </p>
                <p className="text-[14px] text-gray-700">
                  Get a free rental appraisal with your local LJ Hooker property
                  manager to make sure to maximise rental returns.
                </p>
                <p className="text-[14px] text-gray-700">
                  You will get a detailed report of your investment property and
                  find out its current rental value, how it compares to similar
                  rental properties in the area, potential areas for
                  improvement, and much more.
                </p>
                <button className="bg-black text-white px-2 py-1 rounded-sm border-[2px] border-black hover:bg-white hover:text-black duration-200 ease-in">
                  Get my free property appraisal
                </button>
              </div>
              <div className="w-full md:w-1/2 h-[300px] md:h-[700px]">
                <img
                  src={imghome2}
                  alt="Homepage"
                  className="h-full w-full object-cover"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 my-10">
              <div className="my-5 md:my-0">
                <h1 className="text-2xl">Real estate for sale</h1>
                <p className="sub-text">Australian Capital Territory</p>
                <p className="sub-text">New South Wales</p>
                <p className="sub-text">Northern Territory</p>
                <p className="sub-text">Queensland</p>
                <p className="sub-text">South Australia</p>
                <p className="sub-text">More</p>
              </div>
              <div className="my-5 md:my-0">
                <h1 className="text-2xl">Real estate for rent</h1>
                <p className="sub-text">Australian Capital Territory</p>
                <p className="sub-text">New South Wales</p>
                <p className="sub-text">Northern Territory</p>
                <p className="sub-text">Queensland</p>
                <p className="sub-text">South Australia</p>
                <p className="sub-text">More</p>
              </div>
              <div className="my-5 md:my-0">
                <h1 className="text-2xl">Sold real estate</h1>
                <p className="sub-text">Australian Capital Territory</p>
                <p className="sub-text">New South Wales</p>
                <p className="sub-text">Northern Territory</p>
                <p className="sub-text">Queensland</p>
                <p className="sub-text">South Australia</p>
                <p className="sub-text">More</p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
