import React from "react";
import logo from "../assets/ljh-logo-black_152x30.svg";
import { FaInstagram, FaFacebook, FaLinkedin, FaYoutube } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  return (
    <footer className="pt-16 px-10 bg-[#F3F3F3]">
      <div className="flex flex-col md:flex-row md:justify-between items-center mb-3 pb-2  border-b-[2px]">
        <img src={logo} alt="logo" className="w-28" />
        <ul className="flex md:w-48 my-8 md:my-0 gap-5">
          <li>
            <FaInstagram />
          </li>
          <li>
            <FaFacebook />
          </li>
          <li>
            <FaLinkedin />
          </li>
          <li>
            <FaYoutube />
          </li>
        </ul>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 mb-3   pb-3 border-b-[2px]">
        <div className="text-center md:text-left my-2 md:my-0 ">
          <h1 className="text-[17px] font-semibold cursor-pointer">About</h1>
          <div className="hidden md:flex md:flex-col">
            <p className="footer-text">About LJ Hooker</p>
            <p className="footer-text">Find an Agent or Office</p>
            <p className="footer-text">Over 90 Years of Innovation</p>
            <p className="footer-text">Blog</p>
          </div>
        </div>
        <div className="text-center md:text-left my-2 md:my-0">
          <h1
            className="text-[17px] font-semibold cursor-pointer"
            onClick={() => navigate("/buy")}
          >
            Buy
          </h1>
          <div className="hidden md:flex md:flex-col">
            <p className="footer-text">Buying with LJ Hooker</p>
            <p className="footer-text">Find Property to Buy</p>
            <p className="footer-text">Different Ways to Buy a Property</p>
            <p className="footer-text">Steps to Buying</p>
          </div>
        </div>
        <div className="text-center md:text-left my-2 md:my-0">
          <h1
            className="text-[17px] font-semibold cursor-pointer"
            onClick={() => navigate("/sell")}
          >
            Sell
          </h1>
          <div className="hidden md:flex md:flex-col">
            <p className="footer-text">Selling with LJ Hooker</p>
            <p className="footer-text">How Much Is My Property Worth?</p>
            <p className="footer-text">Earn 20,000 Qantas Points</p>
            <p className="footer-text">Selling Real Estate FAQs</p>
          </div>
        </div>
        <div className="text-center md:text-left my-2 md:my-0">
          <h1 className="text-[17px] font-semibold cursor-pointer">Rent</h1>
          <div className="hidden md:flex md:flex-col">
            <p className="footer-text">Renting with LJ Hooker</p>
            <p className="footer-text">Find Property to Rent</p>
            <p className="footer-text">A Guide to Renting Your First Home</p>
            <p className="footer-text">Understanding Tenancy Agreements</p>
          </div>
        </div>
      </div>
      <ul className="flex flex-col md:flex-row py-3 gap-5 text-center md:text-left">
        <li className="text-gray-700 text-[15px] cursor-pointer">
          © LJ Hooker 2024
        </li>
        <li className="text-gray-700 text-[15px] cursor-pointer">Copyright</li>
        <li className="text-gray-700 text-[15px] cursor-pointer">
          Privacy Policy
        </li>
        <li className="text-gray-700 text-[15px] cursor-pointer">
          Terms of Use
        </li>
      </ul>
    </footer>
  );
}
