import React from "react";
import { GiSettingsKnobs } from "react-icons/gi";
import { IoMdSearch } from "react-icons/io";
import Swal from "sweetalert2";

export default function Hero({ title, content, img, search, text }) {
  return (
    <section className="h-[89vh] w-full flex flex-col items-center">
      <img
        src={img}
        alt="hero"
        className="relative object-cover h-full w-full"
      />
      <div className="w-full pt-40 absolute flex flex-col items-center">
        <div className={`w-1/2 text-center ${text}`}>
          <h1 className="text-4xl">{title}</h1>
          <p className="mt-8">{content}</p>
        </div>
        {search && (
          <div className=" hidden w-3/5 p-4 md:flex  gap-4 mt-10 bg-[#ffffffad] rounded-sm">
            <select className="text-[14px] px-4" name="" id="">
              <option value="buy">Buy</option>
              <option value="sell">Sell</option>
              <option value="rent">Rent</option>
              <option value="lease">Lease</option>
            </select>
            <input className="w-full" type="text" />
            <button className="px-5 py-[2px] rounded-sm bg-white text-[14px] hover:bg-black hover:text-white duration-200 ease-in">
              <p className="flex gap-1">
                Filter{" "}
                <span className="text-[18px] leading-normal">
                  <GiSettingsKnobs />
                </span>
              </p>
            </button>
            <button className="px-5 py-[2px] rounded-sm bg-black text-white text-[14px] hover:bg-white hover:text-black duration-200 ease-in">
              <p
                className="flex gap-1"
                onClick={() => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: '<a href="#">Why do I have this issue?</a>',
                  });
                }}
              >
                Search{" "}
                <span className="text-[18px] leading-normal">
                  <IoMdSearch />
                </span>
              </p>
            </button>
          </div>
        )}
      </div>
    </section>
  );
}
